/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Footer__Main {
  background-color: #020711;
  width: 100%;
}
.Footer__Main .Footer__Content .Navigation {
  margin: 0 auto;
  padding: 20px 40px;
  max-width: 1000px;
  background: rgba(255, 255, 255, 0);
  position: relative;
  justify-content: space-between;
}
.Footer__Main .Footer__Content .Navigation__Column {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}
.Footer__Main .Footer__Content .Navigation__Column .Nav__Main ul {
  padding-left: 0px;
}
.Footer__Main .Footer__Content .Navigation__Column .Nav__Main li {
  text-align: center;
}
.Footer__Main .Footer__Content .Navigation__Head {
  color: #6d3ae1;
  font-weight: 500;
  line-height: 36px;
  font-size: 16px;
}
.Footer__Main .Footer__Content .Navigation__Link {
  cursor: pointer;
  text-decoration: none;
  line-height: 28px;
  color: #a1a1a1;
}
.Footer__Main .Footer__Content .Navigation__Link:hover {
  text-decoration: underline;
  color: #f3f3f3;
}
.Footer__Main .Footer__Content .Social {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  cursor: pointer;
}
.Footer__Main .Footer__Content .Social__Button__Container .Oval {
  width: 35px;
  height: 35px;
  background-color: #7237a352;
  border-radius: 50%;
}
.Footer__Main .Footer__Content .Social__Button__Container .Social__Icon {
  display: block;
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin: 6px;
  width: 50%;
}
.Footer__Main .Footer__Content .Social__Button__Container .Social__Icon svg {
  padding-top: 2px;
}
.Footer__Main .Footer__Content .Copyright {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #a1a1a1;
}
