/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.WhatWeDo__Main {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fefefe;
  padding-top: 40px;
  padding-bottom: 60px;
  position: relative;
}
.WhatWeDo__Main .WhatWeDo__Content {
  width: 100%;
  max-width: 1230px;
  justify-content: space-between;
}
.WhatWeDo__Main .WhatWeDo__Content h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  margin: 8px 0 10px;
  color: #5c5c5c;
  text-align: center;
  padding-bottom: 20px;
}
.WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__List {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  margin: 20px auto;
}
.WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Card {
  width: 90%;
  color: #a1a1a1;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: 0 2px 15px 0 rgba(188, 188, 222, 0.25);
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.5px;
  padding: 30px 0px;
  margin: 20px 0px;
}
.WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Card__Title {
  font-size: 16px;
  color: #5c5c5c;
  line-height: 20px;
  font-weight: 400;
  margin: 4px;
  padding: 10px 4px 20px;
}
.WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Card__Desc {
  font-size: 11px;
  font-weight: 600;
  margin: 4px;
  padding: 0px 20px 20px;
}
.WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Button__Container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
@media screen and (min-width: 560px) {
  .WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Card {
    width: 80%;
  }
  .WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Button__Container {
    padding-top: 20px;
  }
}
@media screen and (min-width: 960px) {
  .WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Card {
    width: 27%;
    margin: 0px 3%;
    padding: 3%;
  }
  .WhatWeDo__Main .WhatWeDo__Content .WhatWeDo__Button__Container {
    padding-top: 50px;
  }
}
