@import './ant-vars.less';

* {
	font-family: Montserrat;
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
	overflow: auto;
}
body {
	height: 100%;
}

.AppContainer {
	background-color: #f9f9f9;
	height: 100%;
	width: 100%;
}

p,
label {
	line-height: 1.5em;
}

.svgIcon {
	> svg {
		display: block;
	}
}

button:focus {
	outline: 0;
}
