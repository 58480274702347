/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Landing__Head {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
  position: relative;
  background: #0000009c;
  z-index: 1;
}
.Landing__Head .Landing__Particles__Container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.Landing__Head .Landing__Content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  z-index: 12;
}
.Landing__Head .Landing__Content .Content__Wrapper {
  margin: auto;
  max-width: 560px;
  min-width: 420px;
}
.Landing__Head .Landing__Content .Content__Wrapper h1 {
  font-size: 68px;
  font-weight: 600;
  line-height: 76px;
  margin: 8px 0 28px;
  color: #fefefe;
}
.Landing__Head .Landing__Content .Content__Wrapper p {
  font-size: 20px;
  line-height: 40px;
  color: #fefefe;
}
.Landing__Head .Landing__Content .Image__Wrapper {
  max-width: 500px;
  margin: auto;
}
.Landing__Head .Landing__Content .Image__Wrapper svg {
  height: 300px;
  width: 100%;
  margin: 0;
}
@media screen and (max-width: 560px) {
  .Landing__Head {
    flex-direction: column;
  }
}
@media screen and (max-width: 780px) {
  .Landing__Head {
    min-height: calc(100vh - 64px);
    z-index: 1;
  }
  .Landing__Head .Landing__Particles__Container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: calc(100vh - 64px);
  }
  .Landing__Head .Landing__Content {
    flex-direction: column;
    padding: 4px;
  }
  .Landing__Head .Landing__Content .Image__Wrapper {
    max-width: 500px;
    margin: auto;
  }
  .Landing__Head .Landing__Content .Image__Wrapper svg {
    height: 300px;
    width: 100%;
    margin: 0;
  }
  .Landing__Head .Landing__Content .Content__Wrapper {
    width: 100%;
    text-align: center;
    min-width: auto;
  }
  .Landing__Head .Landing__Content .Content__Wrapper h1 {
    font-size: 48px;
    line-height: 56px;
  }
  .Landing__Head .Landing__Content .Content__Wrapper p {
    font-size: 16px;
    line-height: 36px;
  }
}
