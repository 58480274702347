/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Header {
  position: relative;
  z-index: 2;
  height: 64px;
  color: #fefefe;
}
.Header::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}
.Header__Inner {
  height: 62px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 0;
}
.Header__Inner.Top {
  z-index: 1;
}
.Header__Bar {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 64px;
}
.Header__Logo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.Header__Logo .Logo__Main {
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 2px;
}
.Header__Nav__Toggle {
  cursor: pointer;
  font-size: 20px;
}
@media screen and (min-width: 560px) {
  .Header__Logo :global(svg) {
    margin: 20px 36px 20px 36px;
  }
  .Logo__Main {
    width: 50px;
  }
}
@media screen and (min-width: 800px) {
  .Header__Nav__Toggle {
    display: none;
  }
  .Logo__Main {
    width: 100px;
  }
}
.Header__Separator {
  height: 0px;
  opacity: 1;
  background-color: #6d3ae1;
}
.Header__Background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  background-color: #020711;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
