/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Landing__Main {
  background: linear-gradient(to right top, #fd65b7, #ee55bd, #da48c4, #c33fcc, #a53ad5, #8d49e1, #6f56eb, #4560f3, #1b76fb, #0089fe, #0099fe, #22a9fb);
}
