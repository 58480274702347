/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Error__Main {
  display: flex;
  flex: 1;
  background-color: #f5f5f5;
  height: calc(100vh - 0px);
}
.Error__Main .Error__Card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0px;
  background-color: #fefefe;
  margin: 40px;
}
.Error__Main .Error__Message {
  color: #041330;
  font-size: 20px;
  font-weight: 700;
}
.Error__Main .Error__Detail {
  color: #666;
  font-size: 16px;
}
.Error__Main .Logo__Main {
  height: 200px;
  width: 200px;
  margin: 0;
  padding: 10px;
}
.Error__Main .CTA__Button {
  cursor: pointer;
  background: #6d3ae1;
  border-width: 0px;
  min-width: 120px;
  margin-right: 20px;
  height: 40px;
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;
  color: #fefefe;
  font-size: 14px;
  font-weight: normal;
}
.Error__Main .CTA__Button:hover {
  background: #9164ed;
}
@media screen and (max-width: 840px) {
  .Error__Main .Error__Card {
    background-color: rgba(254, 254, 254, 0);
  }
  .Error__Main .Error__Message {
    font-size: 18px;
  }
  .Error__Main .Error__Detail {
    font-size: 14px;
  }
}
