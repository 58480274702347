/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.RoundIcon__Main .Image {
  margin: 20px auto;
  width: 52px;
  height: 52px;
  object-fit: contain;
}
.RoundIcon__Main .Oval {
  width: 92px;
  margin: 22px auto;
  height: 92px;
  background-color: #fefefe;
  box-shadow: 0 2px 14px 0 rgba(178, 188, 215, 0.31);
  border-radius: 50%;
}
