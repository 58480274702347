/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Showcase__Main {
  display: flex;
  flex: 1;
  text-align: center;
  font-weight: 400;
  padding: 30px 10px 50px;
  margin-left: auto;
  margin-right: auto;
  background: #0000009c;
}
.Showcase__Main .Content__Wrapper {
  width: 100%;
  padding: 10px 40px;
}
.Showcase__Main .Content__Wrapper h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  margin: 8px 0 10px;
  color: #f3f3f3;
}
.Showcase__Main .Content__Wrapper p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #a1a1a1;
  padding-top: 20px;
}
.Showcase__Main .Content__Wrapper .Showcase__Button__Container {
  display: flex;
  justify-content: center;
  padding: 10px 0px 20px;
}
.Showcase__Main .Content__Wrapper .Showcase__Image__Container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.Showcase__Main .Content__Wrapper .Showcase__Image__Container .Showcase__Image {
  width: 80%;
  max-width: 500px;
  height: 100%;
}
.Showcase__Main .Content__Wrapper .Showcase__Image__Container .Absolute {
  position: absolute;
}
.Showcase__Main .Content__Wrapper .Showcase__Image__Container .Hidden {
  visibility: hidden;
}
