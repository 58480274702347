@transition-ease-in: all 0.3s ease-out;
@transition-ease-out: all 0.3s ease-out;
@ease-in: ease-in;

@shadow-1: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
@shadow-2: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);

.text-overflow {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
