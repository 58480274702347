/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Loader__Main {
  display: block;
  background: linear-gradient(to right top, #fd65b7, #ee55bd, #da48c4, #c33fcc, #a53ad5, #8d49e1, #6f56eb, #4560f3, #1b76fb, #0089fe, #0099fe, #22a9fb);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-align: center;
}
.Loader__Main .Loader__Fullscreen {
  position: fixed;
}
.Loader__Main .Loader__Wrapper {
  width: 100px;
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
}
.Loader__Main .Loader__Inner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  text-indent: -12345px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  z-index: 100001;
  animation: spinner 600ms infinite linear;
}
.Loader__Main .Loader__Text {
  width: 100px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 4px;
  color: #fefefe;
}
.Loader__Main.Loader__Hidden {
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.5s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
