/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.SiteNav {
  will-change: transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  transition-duration: 0.25s;
  z-index: 13;
}
.SiteNav__Item {
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
}
.SiteNav__Hamburger {
  color: #fefefe;
}
.SiteNav__Hamburger:hover {
  color: rgba(255, 255, 255, 0.75);
}
.SiteNav__Inner {
  display: flex;
}
@media screen and (max-width: 799px) {
  .SiteNav {
    position: absolute;
    z-index: 13;
    top: 40px;
    left: 0;
    right: 0;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  .SiteNav ul {
    flex-flow: column;
    padding: 0px;
  }
  .SiteNav__Inner {
    display: block;
    background: #fefefe;
    padding: 18px 30px;
    border-radius: 6px;
    flex-grow: 1;
    border: 1px solid #f5f5f5;
    box-shadow: 0 2px 8px rgba(200, 200, 200, 0.3);
  }
  .SiteNav__Start {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  .SiteNav__End {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  .SiteNav.IsOpen {
    visibility: visible;
    transform: none;
    opacity: 1;
  }
  .SiteNav__Item {
    text-decoration: none;
    line-height: 36px;
    font-size: 12px;
    color: #6d3ae1;
    display: block;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
  }
  .SiteNav :global(ul) {
    flex-flow: column;
  }
}
@media screen and (min-width: 800px) {
  .SiteNav__Start {
    display: inline-block;
  }
  .SiteNav__End {
    display: inline-block;
  }
  .SiteNav__Item {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.75);
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
    margin-left: 20px;
    margin-right: 20px;
    transition: color 0.25s ease;
  }
  .SiteNav__Item:hover {
    color: #fefefe;
  }
}
@media screen and (min-width: 960px) {
  .SiteNav :global(li) :global(+) :global(li) {
    margin-left: 40px;
  }
  .SiteNav {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .SiteNav :global(li) :global(+) :global(li) {
    margin-left: 20px;
  }
  .SiteNav__Start {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  .SiteNav__End {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }
}
