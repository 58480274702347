/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* fallback for old browsers */
.Contact__Main {
  display: flex;
  flex: 1;
  text-align: center;
  font-weight: 400;
  padding: 30px 10px 50px;
  background: #fefefe;
  margin-left: auto;
  margin-right: auto;
}
.Contact__Main .Content__Wrapper {
  width: 100%;
  padding: 10px 40px;
}
.Contact__Main .Content__Wrapper h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 60px;
  margin: 8px 0 10px;
  color: #3b3b3b;
}
.Contact__Main .Content__Wrapper p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #5c5c5c;
  padding-top: 20px;
}
.Contact__Main .Content__Wrapper .Contact__Button__Container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
